import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import {Form} from '~components';
import { Images } from '~data';
import Cta from './style';
const CtaSection = () => {
return(
<>
<Cta className="cta-section cta-section--l1 bg-purple-heart dark-mode-texts">
    
  <Cta.Shape className="footer__shape-1">
    <img src={Images.workshop.ProMoShape}  alt="cta shape"/>
  </Cta.Shape>
  <Container>
    <Row className="justify-content-center">
      <Col className="col-lg-7 col-md-8 text-center">
        <Cta.Box>
          <Cta.Title as="h2" fontColor="#fff" data-element="header">訂閱iOS課程相關資訊</Cta.Title>
          <div data-element="subheader">如你因時間或其他原因未能參加此工作坊，你可以輸入電郵地址並按「接收通知」，當我們舉辦另一場的工作坊的時候會立即通知你。請放心，我們也非常討厭垃圾電郵，你所提供的電郵資料只會用作接收工作坊相關通知之用。</div>
          {/* Newsletter */}
          <Cta.Newsletter>
            <Form action="https://app.convertkit.com/forms/3157797/subscriptions" data-sv-form="3157797" data-uid="1edc99a541" data-format="inline" data-version="5" method="post">
              <Cta.NewsletterFromGroup mt="45px">
                <Form.Input className="form-control " type="email" name="email_address" placeholder="你的電郵地址" required />
                <Form.Button type="submit" value="接收通知"/>
              </Cta.NewsletterFromGroup>
            </Form>
          </Cta.Newsletter>
          {/*/ .Newsletter */}
        </Cta.Box>
      </Col>
    </Row>
  </Container>
</Cta>

</>
)
}
 
export default CtaSection