import React from "react"
import HeroSection from "~sections/Hero/HeroSection"
import ContentOne from "~sections/ContentOne"
import ContentTwo from "~sections/ContentTwo"
import Service from "~sections/Service"
import Integration from "~sections/Integration"
import ServicesOne from "~sections/ServicesOne"
import Pricing from "~sections/Pricing"
import FaqSection from "~sections/FaqSection"
import Cta from "~sections/Cta"
import FooterOne from "~sections/FooterOne"
import { PageWrapper } from "~components"
const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
}

export default function Workshop() {
  return (
    <PageWrapper headerConfig={header}>
      <HeroSection/>
      <ServicesOne/>
      <Service/>
      <ContentOne/>
      <ContentTwo/>
      <Integration/>
      <FaqSection/>
      <Pricing/>
      <Cta/>
      <FooterOne/>
    </PageWrapper>
  )
}
