import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';


export default function ContentSectionOne(){

return(
<Content>

  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="學習如何使用 SwiftUI 開發<br className='d-none d-lg-block' />簡單介面和動畫"/> </Content.Title>

          <Content.Text> <SuperTag value="SwiftUI 是 Apple 近年才推出的 UI 框架（Framework），讓初學者更容易掌握 App UI 開發。這工作坊會為你介紹這個新 Framework，而你也會學習使用不同的 SwiftUI 視圖（例如 Text、Image、Stack等）建立簡單 UI 和 動態畫面。"/> </Content.Text>
          
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.Image>
            <Img className="w-100" src="../../assets/image/workshop/swift-playgrounds.png" alt="content" layout="fullWidth" placeholder="blurred"/>
        </Content.Image>
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>
)
}