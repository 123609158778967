import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Images } from '~data'
 import Hero from "./style"
export default function HeroSection(){
return(
<Hero backgroundImage={Images.workshop.heroImage} backgroundOverlay={Images.workshop.heroImgOverlay}>
  <Container>
    <Row className="align-items-center justify-content-center justify-content-md-start">
      {/* Welcome content Area */}
      <Col className="col-xxl-6 col-xl-7 col-lg-8 col-md-8 col-xs-11 order-2 order-lg-1">
        <Hero.Content mb="40px" mbMD="40px" mt="30px" mtLG="0">
        <Hero.Title as="h1" fontColor="#fff">Swift & iOS App開發<br className="d-none d-xs-block" />體驗工作坊</Hero.Title>
          <Hero.Text>專為初學者而設、助你開發自己的 iOS Apps<br className="d-none d-xs-block" /> 學習 Swift 程式語言、SwiftUI、Xcode 和 Swift Playgrounds<br className="d-none d-xs-block" /> </Hero.Text>
        </Hero.Content>
      </Col>
      {/*/ .Welcome Content Area */}
    </Row>
  </Container>
</Hero>
)
}