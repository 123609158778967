import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';


export default function ContentSectionTwo(){

return(
<Content backgrounColor="#fff">
  <Container>
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
        <Content.Image>
            <Img src="../../assets/image/workshop/macbook-xcode.png" alt="content" layout="fullWidth" placeholder="blurred"/>
        </Content.Image>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
        <Content.Box>
          <Content.Title as="h2"> <SuperTag value="Learn by Doing<br class='d-none d-lg-block'> 教學模式"/> </Content.Title>

          <Content.Text> <SuperTag value="AppCoda主力提供iOS程式開發教學，由成立以來，我們都是透過「Learn by Doing」的教學模式，將艱澀的程式概念變得容易明白，吸引更多人學習和開發App。<br class='d-none d-lg-block'><br class='d-none d-lg-block'>我們的教學方法以練習為主，你將會透過實戰應用去學習編寫程式。在這個工作坊，你要親自使用 Swift Playgrounds (Mac/iPad）編寫兩隻簡單的範例 App。此外，你也會學習如何將 Swift Playgrounds 的項目轉移至 Xcode。此外，在工作坊之中所展示的教材和程式碼 (source code) 都可供學員下載重溫。"/> </Content.Text>
        </Content.Box>
      </Col>
    </Row>
    </Content.Block>
  </Container>
</Content>
)
}