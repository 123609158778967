import React from "react"
import { StaticImage as Img } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import Integration from "./style"
import { Images } from "~data"
import SectionTitle from './Components/SectionTitle'
const IntegrationSection = ({ ...rest }) => {
  return (
    <Integration
      backgroundColor="#f2f5fb" id="instructor"
    >
      <Container>
        <Row className="row justify-content-center">
          <Col className="col-xl-10">
            <Integration.Box className="text-center">
              <SectionTitle
                title="導師簡介"
                text="本工作坊由AppCoda創辦人Simon Ng親自教授。<br class='d-none d-sm-block'> 畢業後在不同行業的跨國企業IT部門從事於軟件開發工作，擁有超過十五年程式開發經驗。現專於程式編寫教學工作以及iOS程式開發。 著有《Beginning iOS 15 Programming with Swift》、《Intermediate iOS 15 Programming with Swift》以及《Mastering SwiftUI》。著作亦被翻譯成繁體中文並在台灣以實體書形式推出，其中《iOS 15 App程式設計實力超進化實戰攻略》一書為當地最暢銷的iOS程式書籍。 "
              />
              <img src='https://pbs.twimg.com/profile_images/1455156891190185985/chi6a2BU_400x400.jpg' />
            </Integration.Box>
          </Col>
        </Row>

      </Container>
    </Integration>
  )
}

export default IntegrationSection
