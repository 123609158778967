import React from "react"
import Card  from "./style"

export default function PricingCard({backgroundColor,regularPrice, cardTitle, amount, ...rest}) {
  return (
    <Card backgroundColor="#fff" {...rest}>
        <Card.Top backgroundColor={backgroundColor}>
            <Card.Title as="h3">{cardTitle}</Card.Title>
            <Card.Price>
            <Card.PriceText alignSelf="flex-start">HK$</Card.PriceText>
            <Card.Amount>{amount}</Card.Amount><br/><br/>
            <Card.PriceTextTwo>(原價：<del>${regularPrice}</del>)</Card.PriceTextTwo>
            </Card.Price>
        </Card.Top>
        <Card.List pt="40px">
        </Card.List>
        <Card.Box>
        <noscript><a href="https://www.eventbrite.hk/e/swift-ios-app--tickets-310916869727" rel="noopener noreferrer" target="_blank">Buy Tickets on Eventbrite</a></noscript>
            <Card.Button id="eventbrite-widget-modal-trigger-310916869727" textColor="#fff" hoverTextColor="#fff" backgroundColor={backgroundColor}  hoverBackgroundColor={backgroundColor} rounded={true} sizeX="180px" sizeY="56px" rounded="true">立即報名</Card.Button>
        </Card.Box>
    </Card>
    
  )
}
