const ServiceData =[
        {
          id:"ss1",
          icon:"fas fa-user",
          title: "為初學者而設",
          text: "本工作坊專為有興趣學習 iOS 程式開發的人士而設，無論你是學生還是在職人士都可以參加。就算你未曾有任何編寫程式的經驗，此工作坊都能讓你體驗 iOS app 開發以及學習 Swift 程式語言的基礎知識。",
          iconBackground:"#6001d3"
        },
        {
          id:"ss2",
          icon:"fas fa-cloud",
          title: "線上學習、廣東話授課",
          text:
            "透過線上上課，在家也可以學習。另外，線上課堂提供錄影，讓你之後可以重温課堂講解的內容。我們以廣東話授課並配以英文教材。",
            iconBackground:"#fd346e"
        },
        {
          id:"ss3",
          icon:"fas fa-laptop-code",
          title:"Mac 或 iPad 都可以",
          text:
            "是否一定要擁有 Mac 機才可以參加？以往你一定要擁有 Mac 機才可以學習iOS App開發，現在只要你有 iPad（能運行 iPadOS 14.7 或以上），就可以利用 Swift Playgrounds 開發簡單 App。所以，無論你擁有的是 Macbook、iMac 或 iPad，都可以參加此工作坊。",
            iconBackground:"#1de2cf"
        },
        {
          id:"ss4",
          icon:"fas fa-code",
          title: "助你成為iOS Developer",
          text:
            "這個工作坊會為你打開iOS程式開發之路，為日後成為iOS開發者作出準備。雖然只是短短 3 小時，但就能讓你感受Swift這個程序語言、學習建立簡單 UI 開發並了解成為 iOS Developer 所需掌握的技術。有興趣的話，才繼續進修其他課程。",
            iconBackground:"#ffd700"
        }

    ]
  export default ServiceData;