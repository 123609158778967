import Images from "~data/imageImports";
const serviceData = {
    services: [
        {
          id:"ms1",
          icon: Images.workshop.workshopXcodeLogo,
          iconColor: "#ffffff",
          title: "Swift Playgrounds & Xcode",
          text:
            "本體驗課程主要使用 Swift Playgrounds 作開發工具。Swift Playgrounds 是 Apple 推出的開發及學習工具，讓沒有程式開發經驗的初學者都能輕鬆學習 Swift 和 SwiftUI。最新版的 Swift Playgrounds 更讓你在 iPad 上就能夠開發 iOS Apps。我們也會介紹 Xcode 這個 App 開發必備的工具。",
        },
        {
          id:"ms2",
          icon: Images.workshop.workshopSwiftLogo,
          iconColor:"#ffffff",
          title: "Swift & SwiftUI",
          text:
            "學習 Swift 程式語言和 Apple 最新推出的 SwiftUI 框架開發簡單 App UI 和動態畫面。講解Swift的基本語法，理解 variables、arrays、data type、control flow等等。本體驗課程緊貼最新的Swift 和 SwiftUI版本。",
        },
        {
          id:"ms3",
          icon: Images.workshop.workshopAppStoreLogo,
          iconColor:"#ffffff",
          title: "iOS App 開發",
          text:
            "我們一直以「learn by doing」的教學模式，將艱澀的程式概念變得容易明白。你將會使用Swift程式語言和 SwiftUI建立兩個簡單的範例App。同時，你也會了解到一般 App 上架的流程。",
        },
      ]
  }
  export default serviceData;