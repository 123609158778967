import React from 'react'
import { Accordion,Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { AccordionItemFull } from "~components/Accordion";
import TabList from './Component/Tab'
import Faq from './style'
export default function FaqSection(){
return(
<Faq id="faq">
  <Container>
    <Row className="justify-content-center">
      <Faq.Box>
        <Faq.Title as="h3" mb="61px">
          常見問題
        </Faq.Title>
      </Faq.Box>
    </Row>
    <Faq.Body>
    <Faq.TabWrapper as={Tab.Container}
          id="left-tabs-example"
          defaultActiveKey="first">
      <Row className="justify-content-center">
        <Col className="col-xl-10">
          <Row className="justify-content-center justify-md-content-start">

            <Col className="col-xl-9 col-lg-8 col-md-11">
              

            <Tab.Content className="tab-content tab-content--feature">
              <Tab.Pane eventKey="first">
                  <Faq.Accordion>
                  <Accordion defaultActiveKey="1a">
                  <AccordionItemFull question="我想報讀工作坊，但有什麼程度的程式編寫技巧要求呢？" answer="本工作坊專門為初學者而設計，不需要任何的程式編寫經驗。但本課程同樣適合對學習iOS程式開發有興趣，而本身有其他程式開發經驗的人士報讀。" accKey ="1a"/>
                  <AccordionItemFull question="是否需要自備Mac或iPad參加工作坊？" answer="對！你需要有一部 Mac （能運行 macOS 11.5或以上）或 iPad （能運行 iPadOS 14.7或以上）。在課堂其間，你會使用 Swift Playgrounds 作程式開發，所以你一定要自備能運行 Swift Playgrounds 的 Mac 或 iPad。" accKey ="2a"/>
                  <AccordionItemFull question="是否需要一部 iPhone 作測試 App 之用？" answer="不用。Swift Playgrounds 和 Xcode 都內置模擬器讓你可以在 iPad 或 Mac 測試你所編寫的app。至於實機測試，工作坊中會有更詳盡的說明。." accKey ="3a"/>
                  <AccordionItemFull question="這個工作坊主要是使用 Swift Playgrounds，那麼我所學的能否應用到 Xcode?" answer="沒問題！你所學的 Swift 和 SwiftUI 程式碼都可以在 Swift Playgrounds 和 Xcode 運行。而我們在課堂上會示範如何將 Swift Playgrounds 的開發項目轉移至 Xcode。" accKey ="4a"/>
                  </Accordion>
                  </Faq.Accordion>
              </Tab.Pane>
            </Tab.Content>
            <Faq.ButtonGroup mt="50px">
            <p>還有問題？歡迎透過右下角的聊天功能和我們聯絡。又或者<a className="btn-link text-readical-red ms-2" href="tel://97393959">致電 9739-3959</a>（WhatsApp也可以） 查詢。
            </p>
            </Faq.ButtonGroup>
          </Col>
          </Row>
        </Col>
      </Row>
      </Faq.TabWrapper>
    </Faq.Body>
  </Container>
</Faq>

)
}