import React from 'react'
import { Col, Row , Container } from 'react-bootstrap'
import Pricing from './style'
import PricingCard from "./Components/Card" 


const pricingValues ={
  cardOne : {
    earlybird:{
      price: 680,
    },
    regular:{
      price: 780,
    }
  },
  cardTwo : {
    earlybird:{
      price: 1200,
    },
    regular:{
      price: 1400,
    }
  }
}

export default function PricingSection({...rest}){
  const [yearly,setYearly] = React.useState(true);
  const {cardOne,cardTwo} =  pricingValues;
return(
<Pricing id="buy" backgroundColor="#f3f4f6" {...rest}>
  <Container>
  <Pricing.Box pb="40px" pbXL="70px">
  <Row className="align-items-end justify-content-center">
        <Col xs="12" className="col-xl-8 col-lg-7">
          <Pricing.Box className="text-center text-lg-start">
            <Pricing.Title className="text-center">立即報名</Pricing.Title><br/>
            日期：2022年4月30日（星期六）9:30AM - 12:30PM<br/>
            地點：Zoom 網上課堂<br/><br/>
            本工作坊以廣東話授課，並輔以英文教材。另外，你必須自備一部 Mac 電腦（能運行 macOS 11.5或以上）或 iPad （能運行 iPadOS 14.7或以上）參加此工作坊。上課前，你需要在 Mac 或 iPad 安裝最新版的 Swift Playgrounds。報名後，你會收到電郵詳細說明安裝步驟。<br/><br/>
            早鳥優惠：4月23日(23:59HKT)前報名可享$100折扣。
          </Pricing.Box>
        </Col>
        
    </Row>
    </Pricing.Box>
    <Row className="justify-content-center">
      {/* Single Table */}
      <Col xs="12" className="col-lg-4 col-md-6 col-sm-9 col-xs-10">
          <PricingCard cardTitle={"一人早鳥優惠"} backgroundColor={"#442cd6"} amount={cardOne.earlybird.price} regularPrice={cardOne.regular.price}/>
      </Col>
      {/* Single Table */}
      <Col xs="12" className="col-lg-4 col-md-6 col-sm-9 col-xs-10">
          <PricingCard cardTitle={"二人同行早鳥優惠"} backgroundColor={"#ff5722"} amount={cardTwo.earlybird.price} regularPrice={cardTwo.regular.price}/>
      </Col>
    </Row>
  </Container>
</Pricing>

)
}