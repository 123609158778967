import { Link } from '~components'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Images } from '~data'
import Footer from "./style"
export default function FooterOne(){
return(
    <Footer backgroundColor="#6a26da">
    <Container>
        <Footer.Box pbXL="95px">
        <Row>
            <Col xs="12" className="col-lg-4 col-md-8 col-xs-10">
            <Footer.Widgets className="footer-widgets footer-widgets--l7">
                {/* Brand Logo*/}
                <Footer.Box mb="30px">
                    <Link  to="#">
                        <img src={Images.FooterOne.LogoWhite} alt="logo" />
                    </Link>
                </Footer.Box>
                <Footer.Text mb="36px">
                我們相信，即使沒有任何程式開發的經驗，<br className="d-none d-xl-block" />每個人都可以學懂程式設計和開發應用程式。
                </Footer.Text>

            </Footer.Widgets>
            </Col>
            <Col xs="12" className="col-xl-8">
            <Row>

                <Col xs="6" className="col-md-4 col-xs-6">
                </Col>
                <Col xs="6" className="col-md-4 col-xs-6">
                <Footer.Widgets>
                    <Footer.Title>網站連結</Footer.Title>
                    <Footer.List>
                        <Footer.ListItems>
                            <a href="#">課程重點</a>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <a href="#">教學內容</a>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <a href="#">導師簡介</a>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <a href="#">立即報名</a>
                        </Footer.ListItems>
                    </Footer.List>
                </Footer.Widgets>
                </Col>
                <Col xs="12" className="col-md-4 col-xs-6">
                    <Footer.Widgets>
                        <Footer.Title>聯絡方法</Footer.Title>
                        <Footer.Address className="widgets-address">
                            <Footer.AddressItem>
                                <i className="fa fa-map-marker-alt" />
                                <span>The Hive<br className="d-block" /> 13/F Tai Cheung<br className="d-block" /> Factory Building<br className="d-block" />
                                3 Wing Ming Street,<br className="d-block" />
                                Cheung Sha Wan,<br className="d-block" /> Hong Kong</span>
                            </Footer.AddressItem>
                            <Footer.AddressItem>
                                <i className="fa fa-phone-alt" />
                                
                                <a href="tel://97393959">+852 9739-3959
                                </a>
                            </Footer.AddressItem>
                            <Footer.AddressItem>
                                <i className="fa fa-envelope" />
                                <a href="mailto:support@appcoda.com">support@appcoda.com</a>
                            </Footer.AddressItem>
                        </Footer.Address>
                    </Footer.Widgets>
                </Col>

            </Row>
            </Col>
        </Row>
        </Footer.Box>
        <Footer.Copyright>
        <Footer.CopyrightText>© 2022 AppCoda. 版權所有</Footer.CopyrightText>
        <Footer.SocialShare>
            <Footer.SocialShareItem>
                <a href="https://www.facebook.com/appcodamobile">
                <i className="fab fa-facebook-square" />
                </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
                <a href="https://www.twitter.com/appcodamobile">
                <i className="fab fa-twitter" />
                </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
                <a href="https://www.instagram.com/appcodadotcom">
                <i className="fab fa-instagram" />
                </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
                <a href="https://www.linkedin.com/company/appcoda">
                <i className="fab fa-linkedin" />
                </a>
            </Footer.SocialShareItem>
        </Footer.SocialShare>
        </Footer.Copyright>
    </Container>
    </Footer>
)
}